<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { getMenuList } from '@/network/menu/index.js'
export default {
	name: 'app',
	computed: {},
	methods: {
		saveStore() {
			if (sessionStorage.getItem('store')) {
				this.$store.replaceState(
					Object.assign(
						{},
						this.$store.state,
						JSON.parse(sessionStorage.getItem('store'))
					)
				)
				sessionStorage.setItem('store', '')
			}
			// this.setHeight();
			window.addEventListener('beforeunload', () => {
				sessionStorage.setItem(
					'store',
					JSON.stringify(this.$store.state)
				)
			})
		},
		setHeight() {
			this.clientHeight =
				window.innerHeight ||
				document.documentElement.clientHeight ||
				document.body.clientHeight
			this.clientWidth =
				window.innerWidth ||
				document.documentElement.clientWidth ||
				document.body.clientWidth
			if (window.screen.width <= 768) {
				this.$store.state.isMobileEnd = true
			} else {
				this.$store.state.isMobileEnd = false
			}
		},

		async _getMenuList() {
			await getMenuList().then((res) => {
				if (res.data.code == 200) {
					const { professionList, seriesList } = res.data.data

					this.$store.state.seriesList = seriesList.map((item) => {
						let obj = Object.assign(item, {
							seriesIcon: window.baseUrl + item.seriesIcon,
						})
						return obj
					})
					this.$store.state.solutionList = professionList
				}
			})
		},
	},
	mounted() {
		window.onresize = () => {
			return (() => {
				this.setHeight()
			})()
		}
	},
	created() {
		this._getMenuList()
		if (window.screen.width <= 768) {
			this.$store.state.isMobileEnd = true
		} else {
			this.$store.state.isMobileEnd = false
		}
		this.saveStore()
	},
}
</script>

<style lang="scss">
#app {
	font-family: Source Han Sans, Source Han Sans CN;
	// min-width: 1180px;
	margin: 0 auto;
}
::-webkit-scrollbar {
	width: 6px; /*滚动条宽度*/
	height: 6px; /*滚动条高度*/
}

::-webkit-scrollbar-track {
	// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 3px; /*滚动条的背景区域的圆角*/
	// background: #efefef;
}

::-webkit-scrollbar-thumb {
	border-radius: 3px; /*滚动条的圆角*/
	// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background: #c7c9cd;
}
::-webkit-scrollbar-button {
	display: none;
}
a {
	text-decoration: none;
}
.el-menu--popup {
	border-radius: 6px !important;
	border: 1px solid #0078ff;
}
.el-popper .popper__arrow {
	border-bottom-color: transparent !important;
	&::after {
		display: none;
	}
}
.el-tooltip__popper.is-light {
	border-color: #ffffff;
	box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.1);
	.popper__arrow {
		border: transparent;
	}
}
</style>
