import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loginName: '',
		userName: '',
		userId: '',
		token: '',
		config: {},
		// 页面加载态
		loading: false,
		isMobileEnd: false,
		seriesList: [],
		solutionList: [],
	},
	modules: {},
})
